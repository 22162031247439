
import { userSession } from 'o365-modules';

import Field from "./controls.filter.Field.js";

export default class FieldFilters extends Field {
    constructor(options) {
        super(options);
        for (let [index, filter] of Object.entries(this.filters)) {
            filter.index = index;
        }
        /*
        this.filters = Object.entries(this.filters).sort((a, b) => {
            if (a[1].Default && !b[1].Default) {
                return -1;
            } else {
                return a[0] - b[0];
            }
        }).map(e => e[1]);
        */
    }

    loadStorage() {
        super.loadStorage();
        if (this.storage.value == null) {
            for (let [index, filter] of Object.entries(this.filters)) {
                if (filter.Default) {
                    this.storage.value = index;
                    break;
                }
            }
        }
        if (this.activeFilter) {
            for (let [index, filter] of Object.entries(this.filters)) {
                if (filter === this.activeFilter) {
                    this.storage.value = index;
                    break;
                }
            }
        }
    }

    getFilter() {
        return this.filters[this.storage.value];
    }

    getFilterString() {
        const filter = this.getFilter();
        if (!filter) {
            return null;
        }
        const clause = filter.Filter;
        if (!clause) {
            return null;
        }
        return clause.replace("@Omega365Person_ID", userSession.personId);
    }

    getFilterItems() {
        return [];
    }

    getFilterItemsWhereClause() {
        return this.getFilterString();
    }

    getPills() {
        const filter = this.getFilter();
        if (!filter) {
            return [];
        }
        return [{
            text: filter.Title,
            click: () => {
                this.filter.showFieldSheet = true;
                this.filter.selectField(null);
            },
        }];
    }

    isSelected(index) {
        return this.storage.value == index;
    }

    isSelectedByFilter(filter) {
        return this.isSelected(filter.index);
    }

    setValue(index) {
        this.storage.value = index;

        this.filter.update();
        if (this.onChange) {
            this.onChange(this.getFilter());
        }
    }

    setValueByFilter(filter) {
        return this.setValue(filter.index);
        // for (let [index, filter2] of Object.entries(this.filters)) {
        //     if (filter2 === filter) {
        //         return this.setValue(index);
        //         break;
        //     }
        // }
    }

    getFilterText() {
    }
}